<template>
  <div class="search-form toolbox-search with-background">
    <form method="GET" @submit="submit">
      <input
        ref="inputQuery"
        v-model="searchQuery"
        class="search-form__input"
        name="q"
        type="text"
        :placeholder="placeholderText"
      />
      <div
        v-if="searchQuery"
        class="search-form__btn-clear"
        @click="clearInput"
      >
        <i class="fas fa-times-circle"></i>
      </div>
      <div v-if="!searchQuery" class="search-form__btn-clear">
        <i class="fas fa-search"></i>
      </div>

      <div v-if="filters && allowedFilters" class="filters">
        <p>Filter on \\</p>
        <div class="select-fields">
          <p>Filter on \\</p>
          <select
            v-for="(filterData, index) in allowedFilters"
            :key="index"
            v-model="filterStates[Object.keys(filterData)[0]].selected"
            @change="filterChange"
          >
            <option value="none">
              {{ firstOption }}
            </option>
            <option
              v-for="(subFilter, subName, subIndex) in filterData[
                Object.keys(filterData)[0]
              ]"
              :key="subIndex"
              :value="subFilter.slug"
            >
              {{ subFilter.name }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ToolboxSearch",
  props: {
    initialQuery: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    nav: {
      type: Array,
      default: null,
    },
    page: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      placeholderText: "Search",
      searchQuery: "",
      filterStates: {},
      filtersComplete: {},
      allowedFilters: null,
    };
  },
  computed: {
    firstOption() {
      return Object.keys(this.filterData)[0]
        .replace("nid", "")
        .replace("_", " ")
        .replace("toolboxAuthor", "(Co-)author")
        .toLowerCase();
    },
  },
  mounted() {
    this.searchQuery = this.initialQuery;
    this.getAllowedFilters();
    this.getParams();

    setTimeout(() => {
      this.setFocus();
    });
  },
  methods: {
    clearInput() {
      this.searchQuery = "";
      this.$refs.inputQuery.focus();
    },
    filterChange() {
      this.submit();
    },
    setFocus() {
      //Focus on search input element
      this.$refs.inputQuery.focus();

      //Set window to scrollTop the page had on search
      const url = new URL(window.location.href);
      const param = url.searchParams.get("sp");
      if (param) {
        window.scrollTo(0, param);
      }
    },
    submit(event) {
      if (event) event.preventDefault();
      const search = "?q=" + this.searchQuery; //Set searchlink
      const filters = this.filterStates; //set filters
      const filtersToAdd = Object.keys(filters).filter((k) => {
        return filters[k].selected !== "none";
      }); //Get filled filters
      const scrollPos =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName("html")[0].scrollTop;

      const querystring =
        "&" +
        Object.keys(filtersToAdd)
          .map(
            (key) =>
              filtersToAdd[key] + "=" + filters[filtersToAdd[key]].selected
          )
          .join("&"); //Make querystring

      window.location.href = search + "" + querystring + "&sp=" + scrollPos; //Query new page with this
    },
    getParams() {
      //Get parameters from URL
      const url = new URL(window.location.href);

      //Set filter states
      Object.keys(this.filtersComplete).forEach((filter) => {
        this.$set(this.filterStates, filter, { selected: "none" });
        const param = url.searchParams.get(filter);
        if (param) this.filterStates[filter].selected = param;
      });
    },
    getAllowedFilters() {
      //Get filters that are allowed for this page
      const allowedFilters = this.nav.filter((obj) => {
        return obj.page === this.page;
      })[0].filters;

      if (allowedFilters) this.setAllowedFilters(allowedFilters);
    },
    setAllowedFilters(allowedFilters) {
      const filters = this.filters;
      if (this.dateRange) filters.publication_date = this.dateRange;
      this.filtersComplete = filters;

      //Add allowed filters to object
      const filteredObject = [];

      const keys = Object.keys(filters);
      for (let i = 0; i < keys.length; ++i) {
        const key = keys[i];
        const values = filters[keys[i]];
        if (allowedFilters.includes(key)) {
          const obj = {};
          obj[key] = values;
          filteredObject.push(obj);
        }
      }

      //Set the allowed filters with the final object
      this.allowedFilters = filteredObject;
    },
  },
};
</script>
