<template>
  <a class="navigation-toolbox-item" :href="item.url">
    <h2 class="title">{{ item.title }}</h2>
    <p class="description">{{ item.description }}</p>
  </a>
</template>

<script>
export default {
  name: "NavigationToolboxItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
