<template>
  <div class="carousel__slide__container">
    <img v-if="image" class="object-fit" :src="image" :alt="title" />

    <div v-if="title" :class="['carousel__slide', { 'has-video': video }]">
      <div class="content-box">
        <h2 class="carousel__title" v-text="title" />
        <p v-if="text" class="carousel__text">{{ text }}</p>
      </div>

      <div v-if="cta" class="content-box carousel__button">
        <a :href="cta.url" class="button button--primary" :target="cta.target">
          {{ cta.text }}
        </a>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="video" class="content-box" v-html="video"></div>
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselSlide",
  props: {
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    cta: {
      type: Object,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    video: {
      type: String,
      default: null,
    },
  },
};
</script>
