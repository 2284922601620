<template>
  <div :class="{ 'is-expanded': isExpanded }">
    <div class="navigation-main__title" @click="handleClick">{{ title }}</div>
    <div
      ref="itemList"
      :style="{ maxHeight }"
      class="navigation-main__item-list"
    >
      <a
        v-for="(item, index) in itemList"
        :key="index"
        :href="item.url"
        class="navigation-main__item"
        >{{ item.title }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationMainColumn",
  props: {
    itemList: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initHeight: 0,
      isExpanded: false,
      maxHeight: "none",
    };
  },
  mounted() {
    this.isExpanded = this.isActive;
    this.initHeight = this.$refs.itemList.clientHeight;
    this.setExpanded();
  },
  methods: {
    setExpanded() {
      this.maxHeight = this.isExpanded ? `${this.initHeight}px` : "0px";
    },
    handleClick() {
      this.isExpanded = !this.isExpanded;
      this.setExpanded();
    },
  },
};
</script>
