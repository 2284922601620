<template>
  <nav class="navigation-main">
    <div v-if="columns.length" ref="navBody" class="navigation-main__body">
      <div
        v-for="(column, index) in columns"
        :key="index"
        class="column navigation-main__column"
      >
        <navigation-main-column v-bind="column" />
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavigationMain",
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
};
</script>
