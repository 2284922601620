<template>
  <div v-if="slides.length" class="is-full-height">
    <div ref="slides" class="carousel__container">
      <carousel-slide
        v-for="(slide, index) in slides"
        :key="index"
        v-bind="slide"
      ></carousel-slide>
    </div>

    <div v-if="slides.length > 1" class="carousel__indicator__container">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="carousel__indicator"
        :class="{ 'is-active': index === currentSlide }"
        @click="setCurrentSlide(index)"
      ></div>
    </div>

    <div v-if="slides.length > 1" class="carousel__control__container">
      <div class="columns">
        <div class="column">
          <i
            class="carousel__control-prev"
            :class="{ 'is-disabled': isFirst }"
            @click="prev"
          ></i>
        </div>

        <div class="column has-text-right">
          <i
            class="carousel__control-next"
            :class="{ 'is-disabled': isLast }"
            @click="next"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselSlider",
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSlide: 0,
      isFirst: true,
      isLast: false,
      scrollDistance: 0,
    };
  },
  watch: {
    currentSlide() {
      // if currentSlide changes, show new slide
      this.$refs.slides.scrollLeft = this.currentSlide * this.scrollDistance;
      this.isFirst = this.currentSlide === 0;
      this.isLast = this.currentSlide === this.slides.length - 1;
    },
  },
  mounted() {
    if (!this.slides.length) {
      return;
    }

    window.addEventListener("resize", this.reset);
    this.setScrollDistance();
  },
  methods: {
    reset() {
      this.setScrollDistance();
      this.scrollToCurrentSlide();
    },
    next() {
      this.setCurrentSlide(this.currentSlide + 1);
    },
    prev() {
      this.setCurrentSlide(this.currentSlide - 1);
    },
    setCurrentSlide(index) {
      if (
        index !== this.currentSlide &&
        index >= 0 &&
        index < this.slides.length
      ) {
        this.currentSlide = index;
        this.scrollToCurrentSlide();
      }
    },
    setScrollDistance() {
      this.scrollDistance = this.$refs.slides.querySelector(
        ".carousel__slide__container"
      ).scrollWidth;
    },
    scrollToCurrentSlide() {
      this.$refs.slides.scrollLeft = this.currentSlide * this.scrollDistance;
    },
  },
};
</script>
