<template>
  <nav
    class="navbar__container"
    :class="{ 'is-scrolled': isScrolled, 'is-expanded': isNavExpanded }"
  >
    <div
      class="columns is-gapless is-horizontal navbar"
      :class="{ 'with-background': isNavExpanded || isScrolled }"
    >
      <div class="navbar__branding__container">
        <a :href="siteUrl" class="navbar__branding">
          <img class="logo" :src="labels.logo" alt="Logo De Rijke Noordzee" />
        </a>
      </div>

      <div class="column navbar__section">
        <div class="navbar__menu-toggler__label" @click="handleMenuToggle()">
          {{ isNavExpanded ? labels.close : "Menu" }}
        </div>
      </div>

      <div
        class="column is-3/12 navbar__section has-text-right is-hidden-mobile"
      >
        <a v-if="helpPageUrl" :href="helpPageUrl">
          <i class="icon__biceps"></i> {{ labels.joinUs }}</a
        >
      </div>
      <div class="column navbar__section is-narrow">
        <a :href="searchUrl"><i class="fas fa-search"></i></a>
      </div>
    </div>

    <div
      class="navbar__menu__container"
      :class="{ 'with-background': isNavExpanded }"
    >
      <div class="columns is-right">
        <div class="navbar__menu">
          <navigation-main :columns="mainNavigation"></navigation-main>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    helpPageUrl: {
      type: String,
      required: true,
    },
    searchUrl: {
      type: String,
      required: true,
    },
    siteUrl: {
      type: String,
      required: true,
    },
    mainNavigation: {
      type: Array,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isScrolled: false,
      isNavExpanded: false,
      scrollThreshold: 15,
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    reset() {
      this.setIsScrolledByScrollY();
    },
    handleScroll() {
      if (this.isNavExpanded && this.isScrolled) {
        return;
      }

      this.setIsScrolledByScrollY();
    },
    handleMenuToggle() {
      this.isNavExpanded = !this.isNavExpanded;

      if (!this.isNavExpanded) {
        this.reset();
      }
    },
    setIsScrolledByScrollY() {
      this.isScrolled = window.pageYOffset > this.scrollThreshold;
    },
  },
};
</script>
