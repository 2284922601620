<template>
  <nav class="navigation-toolbox">
    <div v-if="items.length" ref="navBody" class="navigation-toolbox__body">
      <navigation-toolbox-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
      />
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavigationToolbox",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
