<template>
  <div class="search-form">
    <h1 class="tag">
      {{
        searchQuery === "" || initialQuery !== searchQuery
          ? words[lang].lookingFor
          : words[lang].searchedFor + ` ${initialQuery}`
      }}
    </h1>

    <form method="GET" :action="searchUrl">
      <div class="columns">
        <div class="column">
          <div class="search-form__input-control">
            <input
              ref="inputQuery"
              v-model="searchQuery"
              class="search-form__input"
              name="q"
              type="text"
              :placeholder="words[lang].search"
            />
            <div
              :class="{ 'is-invisible': !searchQuery }"
              class="search-form__btn-clear"
              @click="clearInput"
            >
              <i class="fas fa-times-circle"></i>
            </div>
          </div>
        </div>
        <div class="column is-vcentered is-narrow has-text-right-tablet">
          <button
            type="submit"
            class="search-form__btn-submit button button--primary"
          >
            {{ words[lang].search }}
          </button>
        </div>
      </div>
    </form>

    <div
      class="pill folds-vertical"
      :class="{
        'is-collapsed': initialQuery === '' || initialQuery !== searchQuery,
      }"
    >
      {{ total || words[lang].none }}
      {{ 2 > total ? words[lang].result : words[lang].results }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchForm",
  props: {
    initialQuery: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    searchUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: "",
      words: {
        en: {
          result: "result",
          results: "results",
          search: "Search",
          lookingFor: "What are you looking for?",
          searchedFor: "You have searched for:",
          none: "none",
        },
        nl: {
          result: "resultaat",
          results: "resultaten",
          search: "Zoek",
          lookingFor: "Waar ben je naar op zoek?",
          searchedFor: "Je hebt gezocht op:",
          none: "geen",
        },
      },
    };
  },
  mounted() {
    this.searchQuery = this.initialQuery;

    setTimeout(() => {
      this.$refs.inputQuery.focus();
    });
  },
  methods: {
    clearInput() {
      this.searchQuery = "";
      this.$refs.inputQuery.focus();
    },
  },
};
</script>
