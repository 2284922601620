<template>
  <div class="set-order">
    <button
      v-if="!order || order === 'desc'"
      class="order-button"
      @click="setAscending"
    >
      Descending on publication date <i class="fas fa-chevron-down"></i>
    </button>
    <button v-else class="order-button" @click="setDescending">
      Ascending on publication date <i class="fas fa-chevron-up"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "SetOrder",
  props: {
    initialQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      placeholderText: "Search",
      searchQuery: "",
      order: null,
    };
  },
  mounted() {
    const url = new URL(document.URL);
    const params = url.searchParams;
    this.order = params.get("order");
    const scrollPos = params.get("sp");
    if (scrollPos) {
      window.scrollTo(0, scrollPos);
    }
  },
  methods: {
    setAscending() {
      this.updateQueryStringParameter("order", "asc");
    },
    setDescending() {
      this.updateQueryStringParameter("order", "desc");
    },
    updateQueryStringParameter(key, value) {
      const url = new URL(document.URL);
      const params = url.searchParams;

      const scrollPos =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName("html")[0].scrollTop;

      params.set(key, value);
      params.set("sp", scrollPos);
      window.location.href = "?" + params.toString();
    },
  },
};
</script>
